import jwt_decode from 'jwt-decode';
import moment from 'moment';

const numberArray = new Uint32Array(10);

export function test() {
	return null;
}

export const cookieNameGenerator = (name) => {
	if (process.env.REACT_APP_ENV === 'production') {
		return name;
	}
	return `${process.env.REACT_APP_ENV}-${name}`;
};

export const getRequester = () => {
	const acToken = JSON.parse(localStorage.getItem('accessToken'));
	const photo = JSON.parse(localStorage.getItem('photo'));
	const decodedToken = jwt_decode(acToken.accessToken);
	const { details, username } = decodedToken;
	return {
		username,
		email: details.hris_org_tree?.current_person?.email,
		department: details.hris_org_tree?.current_person?.nama_department,
		person_name: details.hris_org_tree?.current_person?.person_name,
		position_name: details.hris_org_tree?.current_person?.nama_posisi,
		auth_type: details.auth_type,
		photo,
	};
};

export const getRoles = () => {
	const roles = JSON.parse(localStorage.getItem('roles'));

	return { roles };
};

export const getaActiveRoles = () => {
	const roles = JSON.parse(localStorage.getItem('active_role'));
	return {
		default_role_name: roles?.default_role_name,
		default_role_code: roles?.default_role_code,
		default_segment_code: roles?.default_segment_code,
		default_segment_name: roles?.default_segment_name,
	};
};

export const getActiveRoles = () => {
	const roles = JSON.parse(localStorage.getItem('active_role'));
	return {
		default_role_name: roles.default_role_name,
		default_role_code: roles.default_role_code,
		default_segment_code: roles.default_segment_code,
		default_segment_name: roles.default_segment_name,
	};
};

export const getRouting = (data) => {
	const rawRoute = Object.keys(data)
		.map((item) => {
			if (data[item].subMenu) {
				return Object.keys(data[item].subMenu).map((sb) => {
					return {
						path: data[item].subMenu[sb].path,
						element: data[item].subMenu[sb].element,
					};
				});
			}
			return null;
		})
		.filter((e) => e);
	const merge = [].concat(...rawRoute);
	return merge;
};

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(crypto.getRandomValues(numberArray) * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	// eslint-disable-next-line func-names
	return function (...args) {
		const context = this;
		return new Promise((resolve) => {
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				timeout = null;
				resolve(func.apply(context, args));
			}, wait);
		});
	};
};

export const isEmptyObject = (obj) => {
	return obj == null || !Object.keys(obj).length;
};

export const isValidDateTime = (value) => {
	return moment(value).isValid() && typeof value !== 'number';
};

export const isValidNumber = (value) => {
	return typeof value === 'number' && moment(value).isValid();
};

const thousandformat = (nStr, inD, outD, sep) => {
	nStr += '';
	const dpos = nStr.indexOf(inD);
	let nStrEnd = '';
	if (dpos !== -1) {
		nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
		nStr = nStr.substring(0, dpos);
	}
	const rgx = /(\d+)(\d{3})/;
	while (rgx.test(nStr)) {
		nStr = nStr.replace(rgx, `$1 ${sep} $2`);
	}
	return nStr + nStrEnd;
};

export const GetThousandDelimiterFormat = (cell) => {
	if (cell || String(cell) === '0' || !Number.isNaN(parseFloat(cell))) {
		if (parseFloat(cell) % 1 != 0) {
			return thousandformat(Number(cell).toFixed(2), '.', '.', ',');
		}
		return thousandformat(cell, '.', '.', ',');
	}
	return '';
};

export const isEmptyArray = (arr) => {
	return arr == null || !arr.length;
};

export function formatNumber(amount) {
	return (amount ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

import * as React from 'react';

const SvgCustomPercent = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height='24px'
		viewBox='0 -960 960 960'
		width='24px'
		fill='#e8eaed'
		{...props}>
		<path d='M300-520q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T360-660q0-25-17.5-42.5T300-720q-25 0-42.5 17.5T240-660q0 25 17.5 42.5T300-600Zm360 440q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T720-300q0-25-17.5-42.5T660-360q-25 0-42.5 17.5T600-300q0 25 17.5 42.5T660-240Zm-444 80-56-56 584-584 56 56-584 584Z' />
	</svg>
);

export default SvgCustomPercent;

import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const MasterData = {
	Segment: lazy(() => import('../views/Segment')),
	Regional: lazy(() => import('../views/Regional')),
	Area: lazy(() => import('../views/Area')),
	Roles: lazy(() => import('../views/Roles')),
	FieldInput: lazy(() => import('../views/FieldInput')),
	Distance: lazy(() => import('../views/Distance')),
	Activity: lazy(() => import('../views/Activity')),
	ApprovalSetting: lazy(() => import('../views/ApprovalSetting')),
	DataUser: lazy(() => import('../views/DataUser')),
	Target: lazy(() => import('../views/Target')),
	StockTransaction: lazy(() => import('../views/StockTransaction')),
	TemplateExcel: lazy(() => import('../views/bagus/templateExcel')),
};

const JourneyPlan = {
	SettingPjp: lazy(() => import('../views/SettingPjp')),
	ExportExcel: lazy(() => import('../views/MyExportExcel')),
};

const Report = {
	Kpi: lazy(() => import('../views/Kpi')),
	LeaveRequest: lazy(() => import('../views/handri/LeaveRequest')),
	ExportStore: lazy(() => import('../views/handri/ExportStore')),
};

const Setting = {
	StructureBagian: lazy(() => import('../views/StructureBagian')),
	StructureJabatan: lazy(() => import('../views/StructureJabatan')),
};

const Distributor = {
	ImportTransaction: lazy(() => import('../views/dimas/ImportTransaction/index')),
	DistributorTransaction: lazy(() => import('../views/DistributorTransaction')),
	MatchingProduct: lazy(() => import('../views/handri/MatchingProduct')),
	TargetDistributor: lazy(() => import('../views/afif/TargetDistributor')),
	DuplicateCustomer: lazy(() => import('../views/rio/DuplicateCustomer')),
	AnnotateCustomer: lazy(() => import('../views/sodiq/AnnotateCustomer')),
};

const EllenkaSquad = {
	Exhibition: lazy(() => import('../views/rio/Exhibition/index')),
	ExhibitionAdmin: lazy(() => import('../views/rio/ExhibitionAdmin/index')),
	ExhibitionNewCreator: lazy(() => import('../views/bakti/exhibition')),
	ExhibitionNewPIC: lazy(() => import('../views/bakti/exhibition')),
	ExhibitionNewAdmin: lazy(() => import('../views/bakti/exhibition')),
	ExhibitionNewAji: lazy(() => import('../views/aji/exhibition')),
};

const SKP_TT = {
	CreateSKP: lazy(() => import('../views/bakti/create-skp')),
	ApprovalSKP: lazy(() => import('../views/bakti/approval-skp')),
	TTClaim: lazy(() => import('../views/bakti/claim-tt')),
	ApprovalTTClaim: lazy(() => import('../views/bakti/approval-claim-tt')),
	SKPClaim: lazy(() => import('../views/afif/skp-claim')),
	ApprovalSKPClaim: lazy(() => import('../views/afif/skp-claim-approval')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <JourneyPlan.SettingPjp />,
		index: 'SettingPjp',
		exact: true,
	},
	{
		path: null,
		element: <JourneyPlan.ExportExcel />,
		index: 'ExportExcel',
		exact: true,
	},
	{
		path: null,
		element: <Report.LeaveRequest />,
		index: 'LeaveRequest',
		exact: true,
	},
	{
		path: null,
		element: <Report.ExportStore />,
		index: 'ExportStore',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Segment />,
		index: 'segment',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Regional />,
		index: 'regional',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Area />,
		index: 'area',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Roles />,
		index: 'role',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.FieldInput />,
		index: 'fieldInput',
		exact: true,
	},
	{
		element: <MasterData.Distance />,
		index: 'distance',
		exact: true,
	},
	{
		element: <MasterData.Activity />,
		index: 'activity',
		path: null,
	},
	{
		element: <MasterData.ApprovalSetting />,
		index: 'ApprovalSetting',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.DataUser />,
		index: 'user',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Target />,
		index: 'Target',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.StockTransaction />,
		index: 'StockTransaction',
		exact: true,
	},
	{
		element: <Report.Kpi />,
		index: 'Kpi',
		exact: true,
	},
	{
		path: null,
		element: <Setting.StructureBagian />,
		index: 'StructureBagian',
		exact: true,
	},
	{
		path: null,
		element: <Setting.StructureJabatan />,
		index: 'StructureJabatan',
		exact: true,
	},
	{
		path: null,
		element: <Distributor.ImportTransaction />,
		index: 'ImportTransaction',
		exact: true,
	},
	{
		path: null,
		element: <Distributor.MatchingProduct />,
		index: 'MatchingProduct',
	},
	{
		path: null,
		element: <MasterData.TemplateExcel />,
		index: 'TemplateExcel',
		exact: true,
	},
	{
		path: null,
		element: <Distributor.DuplicateCustomer />,
		index: 'DuplicateCustomer',
		exact: true,
	},
	{
		path: null,
		element: <Distributor.TargetDistributor />,
		index: 'TargetDistributor',
		exact: true,
	},
	{
		path: null,
		element: <Distributor.AnnotateCustomer />,
		index: 'AnnotateCustomer',
		exact: true,
	},
	{
		path: null,
		element: <Distributor.DistributorTransaction />,
		index: 'DistributorTransaction',
	},
	{
		path: null,
		element: <EllenkaSquad.ExhibitionNewCreator type='creator' />,
		index: 'Exhibition',
	},
	{
		path: null,
		element: <EllenkaSquad.ExhibitionNewAdmin type='admin' />,
		index: 'ExhibitionAdmin',
	},
	{
		path: null,
		element: <EllenkaSquad.ExhibitionNewPIC type='pic' />,
		index: 'PICExhibition',
	},
	// SKP-TT
	{
		path: '/skp-input/create-skp',
		element: <SKP_TT.CreateSKP />,
		index: 'CreateSKP',
	},
	{
		path: '/skp-input/approval-skp',
		element: <SKP_TT.ApprovalSKP />,
		index: 'ApprovalSKP',
	},
	{
		path: '/skp-claim/tt-claim',
		element: <SKP_TT.TTClaim />,
		index: 'TTClaim',
	},
	{
		path: '/skp-input/approval-tt-claim',
		element: <SKP_TT.ApprovalTTClaim />,
		index: 'ApprovalTTClaim',
	},
	{
		path: '/skp-input/skp-claim',
		element: <SKP_TT.SKPClaim />,
		index: 'SKPClaim',
	},
	{
		path: '/skp-input/approval-skp-claim',
		element: <SKP_TT.ApprovalSKPClaim />,
		index: 'ApprovalSKPClaim',
	},
];
export default contents;
